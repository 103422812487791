@font-face {
  font-family: "Europa";
  src: url("./assets/font/Europa-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Europa-Bold";
  src: url("./assets/font/Europa-Bold.ttf") format("truetype");
  font-weight: bold;
}

body {
  font-family: "Europa", sans-serif !important  ;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  padding-left: 0px;
  padding-right: 0px;
}

/* Map Viewer */
.leaflet-control-layers-base {
  display: flex;
  flex-wrap: wrap;
  width: 300px;
}

.pa-d-r-5 {
  padding-right: 5px;
}

.pa-d-r-10 {
  padding-right: 10px;
}

.pa-d-r-15 {
  padding-right: 15px;
}

.pa-d-r-20 {
  padding-right: 15px;
}

.pa-d-l-5 {
  padding-left: 5px;
}

.pa-d-l-10 {
  padding-left: 10px;
}

.pa-d-l-15 {
  padding-left: 15px;
}

.pa-d-l-20 {
  padding-left: 15px;
}

.pa-d-5 {
  padding: 5px;
}

.pa-d-10 {
  padding: 10px;
}

.pa-d-15 {
  padding: 15px;
}

.pd-0 {
  padding: 0 !important;
}

.text-right {
  text-align: right !important;
}

.leaflet-control-layers-base label {
  display: block;
  text-align: center;
  flex: 0 0 33.3333%;
  padding: 5px;
}

.leaflet-control-layers.leaflet-control.leaflet-control-layers-expanded input {
  display: none;
}

.leaflet-control-layers.leaflet-control.leaflet-control-layers-expanded img {
  width: 100px;
}

.base-title-item {
  font-weight: 400;
  font-size: 12px;
  text-align: center;
}

.title-limit {
  text-decoration: line-through;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.collapsed-icon {
  width: 70%;
}

.result-tooltip {
  background-color: white;
  border-width: medium;
  border-color: #de0000;
  font-size: smaller;
}

.moving-tooltip {
  background-color: rgba(255, 255, 255, 0.7);
  background-clip: padding-box;
  opacity: 0.5;
  border: dotted;
  border-color: red;
  font-size: smaller;
}

.plus-length {
  padding-left: 45px;
}

/*leaflet window*/

.nonmodal {
  z-index: 6000;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 7000;
  background-color: rgba(0, 0, 0, 0.7);
}

.visible {
  display: block;
  opacity: 1;
}

.measuement-tool {
  min-width: 250px !important;
  max-width: 350px !important;
  padding: 10px !important;
}

.ma-r-70 {
  margin-right: 70px !important;
}

.ma-r-20 {
  margin-right: 20px !important;
}

.ma-r-34 {
  margin-right: 34px !important;
}

.cusorPoint {
  cursor: pointer;
}

.ma-l-5 {
  margin-left: 5px !important;
}

.ma-l-10 {
  margin-left: 10px !important;
}

.ma-l-20 {
  margin-left: 20px !important;
}

.ma-t-20 {
  margin-top: 20px !important;
}

.ma-t-10 {
  margin-top: 10px !important;
}

.height-80 {
  height: 80px !important;
}

.digga2-toolbar {
  display: flex;
}

.digga-leaflet-control {
  flex-direction: row-reverse;
}

.MuiIconButton-root {
  padding: 3px !important;
}

.fa-square-o:before {
  content: "\f0c9";
}

label.selection-label {
  line-height: 24px;
  margin: auto;
  font-weight: 600;
}

.leaflet-control-lasso {
  display: none !important;
}

.leaflet-control-mouseposition {
  display: none !important;
}

.box-icon-draw {
  width: 36px;
  padding: 2px;
  border-radius: 6px;
}

.activeClickMeasuretool {
  border: 1px solid #ccc;
}

.divider {
  margin: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.cusorPiont {
  cursor: pointer !important;
}

.css-lzr3bi-LoadingOverlayWrapperBase {
  z-index: 1001 !important;
}
